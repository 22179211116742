@charset "UTF-8";
body{
  padding-top: 50px;
  font-family: 'Microsoft Yahei','微软雅黑','Hiragino Sans GB', arial,;
}
// 页眉
.head-wrap{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  height: 50px;
  background-color: $brand-success;
  .logo img{
    width: 220px;
  }
}

// 主题图
.banner-wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  padding: 0 30px;
  background: url("../images/joinus/banner.jpg") no-repeat center top;
  background-size: cover;
  color: $brand-warning;
  text-align: center;
  dt{
    font-size: 24px;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
  }
  dd{
    margin-top: 1%;
    font-size: 14px;
    text-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.8);
  }
}

// 全局
.page-wrap{
  padding: 40px 0;
  .page-title{
    position: relative;
    margin-bottom: 80px;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    strong{
      color: $brand-success;
      margin-right: 15px;
    }
    span{
      display: inline-block;
      position: absolute;
      bottom: -24px;
      margin-left: -64px;
      .line1{
        display: inline-block;
        width: 32px;
        height: 4px;
        background-color: $brand-success;
      }
      .line2{
        display: inline-block;
        width: 32px;
        height: 4px;
        background-color: $brand-warning;
      }
    }
  }
}

// 关于品牌
.brand-wrap{
  dt{
    margin-top: 20px;
    font-size: 24px;
    font-weight: bolder;
    line-height: 1.8;
  }
  dd{
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.6;
    text-indent: 2em;
    text-align: justify;
  }
}

// 发展历程
.development-wrap{
  background: url("../images/joinus/deve_bg.jpg") no-repeat center top;
  background-size: cover;
  .page-title{
    color: $brand-warning;
    strong{
      color: #ffffff;
    }
    span .line1{
      background-color: #ffffff;
    }
  }
}
.development-slide{
  .map-item{
    margin-bottom: 20px;
    line-height: 1.8;
    padding: 10px 30px;
    background-color: #ffffff;
    border-radius: 8px;
  }
}
.carousel{
  .btn-left,.btn-right{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100;
    display: inline-block;
    width: 60px;
    height: 60px;
  }
  .btn-left{
    background: url("../images/joinus/btn_prev.png") no-repeat center center;
    background-size: cover;
  }
  .btn-right{
    background: url("../images/joinus/btn_next.png") no-repeat center center;
    background-size: cover;
  }
}

// 行业前景
.prospect-wrap{
  text-align: center;
}
.prospect-inner{
  position: relative;
  margin-top: 12%;
  figure{
    margin-bottom: 10px;
  }
  img{
    border-radius: 8px;
  }
  .prospect-img{
    //margin-top: -40%;
  }
}
.prospect-other{
  padding: 0 15px;
  margin-top: 40px;
  h3{
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
  }
  p{
    font-size: 14px;
    line-height: 2;
  }
}

// 茶空间
.space-wrap{
  background-color: #f0f0f0;
  .map-img{
    img{
      border-radius: 8px;
    }
  }
  p{
    color: #666;
    line-height: 1.8;
  }
  .icon{
    position: relative;
    top: 5px;
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
  .icon-terminal1{
    background-image: url("../images/joinus/icon_guanli.png");
  }
  .icon-terminal2{
    background-image: url("../images/joinus/icon_yingxiao.png");
  }
  .icon-terminal3{
    background-image: url("../images/joinus/icon_peixun.png");
  }
  .icon-terminal4{
    background-image: url("../images/joinus/icon_peihuo.png");
  }
}

// 投资开店
.investment-wrap{
  padding-bottom: 0;
  p{
    margin-bottom: 30px;
    line-height: 2;
    text-indent: 2em;
  }
}

// 产品展示
.product-wrap{
  padding-bottom: 0;
  .product-inner{
    display: block;
  }
}

// 我们优势
.advantage-wrap{
  padding-bottom: 50px;
  .map-item{
    position: relative;
    display: flex;
    margin-bottom: 30px;
    .map-icon{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 60px;
      img{
        width: 60px;
        height: 60px;
      }
    }
    .map-info{
      margin-left: 70px;
      h4{
        font-size: 24px;
        font-weight: bolder;
        color: $brand-warning;
      }
      p{
        text-align: justify;
      }
    }
  }
}

// 荣誉资质
.honor-wrap{
  background-color: #f0f0f0;
  figcaption{
    padding: 10px 0;
  }
}

// 加入我们
.join-wrap{
  .join-form{
    h4{
      font-size: 22px;
      font-weight: bolder;
    }
    p{
      margin-top: 15px;
      margin-bottom: 30px;
      font-size: 14px;
    }
    .form-group{
      margin-bottom: 30px;
    }
    .label-name{
      font-size: 18px;
      margin-bottom: 10px;
    }
    .form-control{
      height: 45px;
    }
    input{
      font-size: 18px;
    }
    .btn{
      height: 45px;
      font-size: 18px;
    }
  }
  .map-wrap{
    height: 550px;
  }
}

// 页脚
.foot-wrap{
  color: $brand-warning;
  background-color: $brand-success;
  .foot-inner{
    padding: 40px 0;
  }
  .foot-info{
    text-align: center;
    .tel-wrap{
      position: relative;
      font-size: 24px;
      font-weight: bolder;
      margin-left: 40px;
      margin-bottom: 20px;
      margin-top: 40px;
      .tel-icon{
        display: inline-block;
        position: absolute;
        left: -40px;
        top: 6px;
        width: 28px;
        height: 28px;
        background: url("../images/joinus/phone_call.png") no-repeat center center;
        background-size: cover;
      }
      a{
        color: $brand-warning;
      }
    }
  }
  .code-wrap{
    display: flex;
    margin-top: 30px;
    figure{
      margin: 0 10px;
      text-align: center;
      figcaption{
        line-height: 3;
      }
    }
  }
  .copyright{
    text-align: center;
    padding: 20px 0;
    background-color: darken($brand-success,60%);
  }
}

// 右侧浮动
.sidebar{
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  .btn-bar{
    display: block;
    width: 44px;
    height: 44px;
    margin-bottom: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .btn-tel{
    background-image: url("../images/joinus/sidebar_tel.png");
    &:hover{
      background-image: url("../images/joinus/sidebar_tel_active.png");
    }
  }
  .btn-kefu{
    background-image: url("../images/joinus/sidebar_kefu.png");
    &:hover{
      background-image: url("../images/joinus/sidebar_kefu_active.png");
    }
  }
  .btn-qq{
    background-image: url("../images/joinus/sidebar_qq.png");
    &:hover{
      background-image: url("../images/joinus/sidebar_qq_active.png");
    }
  }
  .btn-top{
    display: none;
    background-image: url("../images/joinus/sidebar_top.png");
    &:hover{
      background-image: url("../images/joinus/sidebar_top_active.png");
    }
  }
  .open-wrap{
    display: none;
    position: absolute;
    right: 60px;
    width: 160px;
    text-align: center;
    padding: 10px 15px;
    font-size: 18px;
    font-weight: bolder;
    color: #ffffff;
    background-color: $brand-warning;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    .arrow{
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid $brand-warning;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
    a{
      color: #ffffff;
    }
  }
  .open-tel{
    top: 0;
  }
}