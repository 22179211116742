@charset "UTF-8";
@media screen and (min-width: 768px) {
  body{
    padding-top: 60px;
  }
  // 页眉
  .head-wrap{
    height: 60px;
    .logo img{
      width: 320px;
    }
  }

  // 全局
  .page-wrap {
    padding: 80px 0;
    .page-title{
      font-size: 36px;
    }
  }

  // 主题图
  .banner-wrap{
    height: 600px;
    dt{
      font-size: 60px;
    }
    dd{
      font-size: 28px;
    }
  }
  // 关于品牌
  .brand-wrap{
    dt{
      margin-top: 0;
      font-size: 36px;
    }
    dd{
      font-size: 16px;
    }
  }

  // 发展历程
  .development-slide{
    .map-item{
      height: 220px;
    }
  }

  // 投资开店
  .investment-wrap{
    padding-bottom: 0;
  }

  // 产品展示
  .product-wrap{
    .product-inner{
      display: flex;
    }
  }

  // 行业前景
  .prospect-inner{
    .prospect-img{
      margin-top: -40%;
    }
  }
  .prospect-other{
    padding: 0 20%;
    margin-top: 80px;
    h3{
      font-size: 36px;
    }
    p{
      font-size: 18px;
    }
  }

  // 荣誉资质
  .honor-wrap{
    figcaption{
      padding: 10px 20px;
    }
  }

  // 加入我们
  .join-wrap{
    .join-form{
      h4{
        font-size: 24px;
      }
      p{
        font-size: 16px;
      }
    }
  }
  // 页脚
  .foot-wrap{
    .foot-info{
      text-align: left;
      .tel-wrap{
        margin-top: 0;
      }
    }
  }
}